<div class="u-container-small u-margin-0-auto">
    <app-base-step-container previousLabelResource="UI.PREVIOUS">
        <h3 class="u-margin-bottom">{{ 'MOVE.DETAILS.TITLE' | translate }}</h3>
        <div class="u-service-card">
            <app-loading-container [loading]="loading">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <app-alert
                        *ngIf="processed"
                        [context]="uiContext.Success"
                        icon="check_circle"
                        [iconContext]="uiContext.Success"
                        class="u-w100p u-margin-bottom-xs u-margin-top-xs"
                    >
                        <h6 class="u-margin-bottom-xs">{{ 'MOVE.DETAILS.PROCESSED.TITLE' | translate }}</h6>
                        <p>
                            {{ 'MOVE.DETAILS.PROCESSED.BODY' | translate }}
                            <a class="u-color-success u-font-weight-normal" [href]="contactUsLink$ | async" target="_blank">
                                {{ 'MOVE.DETAILS.PROCESSED.CONTACT_US' | translate }}
                            </a>
                        </p>
                    </app-alert>

                    <app-date-input [label]="'COMMON.TRANSFER_DATE' | translate" formControlName="movingDate"></app-date-input>

                    <div class="u-flex-row u-flex-justify-content-end">
                        <button smvd-ui-button type="submit" [disabled]="processed" [context]="uiContext.PrimaryDark">
                            {{ 'SAVE' | translate }}
                        </button>
                    </div>
                </form>
            </app-loading-container>
        </div>
    </app-base-step-container>
</div>
