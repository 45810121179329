import { ChangeDetectorRef, Component, OnInit, Optional, ViewChild } from '@angular/core';
import { MatColumnDef, MatTable } from '@angular/material/table';
import { Move } from '@app/move/interfaces/move';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { AppNavigationSandbox } from '@app/navigation/sandboxes/navigation.sandbox';
import { DbUtils } from '@smooved/core';
import { MoverRole } from '@app/move/enums/mover-role.enum';

@Component({
    selector: 'app-table-column-impersonate',
    template: `
        <ng-container matColumnDef="impersonate">
            <th mat-header-cell *matHeaderCellDef class="__cell __cell--impersonate"></th>
            <td mat-cell *matCellDef="let row" class="__cell __no-ellispis">
                @if (row.user.role === MoverRole.Transferee) {
                    <app-impersonate [disabled]="!row.isDraft" [move]="row" returnUrl="/" class="__impersonate"></app-impersonate>
                }
            </td>
        </ng-container>
    `,
    styleUrls: ['./table-column-impersonate.component.scss'],
})
export class TableColumnImpersonateComponent implements OnInit {
    @ViewChild(MatColumnDef) columnDef: MatColumnDef;

    constructor(
        @Optional() public table: MatTable<any>,
        private moveSandbox: MoveSandbox,
        private navigationSandbox: AppNavigationSandbox,
        private cdRef: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        if (this.table) {
            this.cdRef.detectChanges();
            this.table.addColumnDef(this.columnDef);
        }
    }

    public impersonate(e: Event, move: Move): void {
        e.stopImmediatePropagation();
        this.moveSandbox.impersonate(DbUtils.getStringId(move)).subscribe(() => {
            this.navigationSandbox.goToHome();
        });
    }

    protected readonly MoverRole = MoverRole;
}
