import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EnergySuggestionsQuery } from '@app/energy/interfaces/energy-suggestions-query';
import { checkboxRequired } from '@app/form/validators/checkbox-required.validator';
import { homeSizeStep } from '@app/meta/components/home-description/home-description.constants';
import { HomeDescriptionUtils } from '@app/meta/components/home-description/home-description.utils';
import { professionalOptions } from '@app/move/constants/move.constants';
import { Gender } from '@app/move/enums/gender.enum';
import { MoverRole } from '@app/move/enums/mover-role.enum';
import { Transferee } from '@app/move/enums/transferee.enum';
import { Move } from '@app/move/interfaces/move';
import { User } from '@app/move/interfaces/user';
import { MoveConversionSandbox } from '@app/move/sandboxes/move-conversion.sandbox';
import { MoveSandbox } from '@app/move/sandboxes/move.sandbox';
import { MoveService } from '@app/move/services/move.service';
import { MoveUtils } from '@app/move/state/move.utils';
import { RealEstateGroupSandbox } from '@app/real-estate-group/sandboxes/real-estate-group.sandbox';
import { AppUiSandbox } from '@app/ui/sandboxes/ui.sandbox';
import { EnergySuggestion } from '@app/wizard/energy/interfaces/energy-suggestion';
import { EnergySandbox } from '@app/wizard/energy/sandboxes/energy.sandbox';
import { environment } from '@environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { EnergyType } from '@shared/move/enums/energy-type.enum';
import { Address, ArrayUtils, DateUtils, DbUtils, HomeDescription, inject, ObjectUtils, RxjsComponent } from '@smooved/core';
import {
    ButtonAppearance,
    ButtonSize,
    CheckInput,
    homeDescriptionOptions,
    maskVatNumber,
    ModalSandbox,
    PhoneNumberValidators,
    SiblingValidators,
    UiContext,
    UiDirection,
} from '@smooved/ui';
import { cloneDeep, merge } from 'lodash';
import { combineLatest, firstValueFrom, Observable } from 'rxjs';
import { debounceTime, filter, map, startWith, takeUntil, tap } from 'rxjs/operators';
import { EnergyStopSuppliersFormComponent } from '@app/energy/components/energy-stop-suppliers-form/energy-stop-suppliers-form.component';
import { EnergyStopForm } from '@app/energy/components/energy-stop-suppliers-form/energy-stop-suppliers-form.constants';
import { AddressComponent } from '@app/form/components/address/address.component';
import { EnergyStop } from '@app/wizard/energy/interfaces/energy-stop';
import { LeaverContactInvoice } from '../../enums/leaver-contact-invoice.enum';
import {
    addLeaverOptions,
    EnergyOption,
    energyOptions,
    energyTypeOptions,
    fixedTariffOptions,
    genderOptions,
    insurancesLogoUri,
    insurancesOptions,
    languageOptions,
    leaverContactInvoiceOptions,
    leaverFinalInvoiceOptions,
    leaverTypeOptions,
    realEstateAgentConsentOptionWithLeaver,
    realEstateAgentConsentOptionWithoutLeaver,
    solarPanelOption,
    telecomOptions,
    telecomProviderOptions,
    transfereeTypeOptions,
    transfereeTypeTenantOptions,
    vacancyOption,
} from './lead-passing-form.constants';
import { LeadPassingForm } from './lead-passing-form.enum';
import { LeadPassingForm as ILeadPassingForm } from './lead-passing-form.interface';
import { LeadPassingValidators } from './lead-passing-form.validators';
import { EnergyStopStore } from '@app/services/energy-stop/store/energy-stop.store';
import { EnergyStopRequest } from '@app/services/energy-stop/types/energy-stop-request';
import { EnergyStopActiveServices } from '@app/services/energy-stop/enums/energy-stop-active-services';

@Component({
    selector: 'app-lead-passing-form',
    templateUrl: './lead-passing-form.component.html',
    styleUrls: ['./lead-passing-form.component.scss'],
})
export class LeadPassingFormComponent extends RxjsComponent implements OnInit {
    @Input() public initialData = false;
    @Input() public showPrevious = false;

    @Output() public previous: EventEmitter<void> = new EventEmitter<void>();
    @Output() public submitSuccess: EventEmitter<Move> = new EventEmitter<Move>();

    @ViewChild(AddressComponent, { static: true }) public addressComponent: AddressComponent;
    @ViewChild(EnergyStopSuppliersFormComponent) suppliersForm: EnergyStopSuppliersFormComponent;

    protected readonly energyStopStore = inject(EnergyStopStore);

    public readonly leaverTypeOptions = leaverTypeOptions;
    public readonly transfereeTypeOptions = transfereeTypeOptions;
    public readonly transfereeTypeTenantOptions = transfereeTypeTenantOptions;
    public readonly languageOptions = languageOptions;
    public readonly homeDescriptionOptions = homeDescriptionOptions;
    public readonly energyTypeOptions = energyTypeOptions;
    public readonly vacancyOption = vacancyOption;
    public readonly solarPanelOption = solarPanelOption;
    public readonly genderOptions = genderOptions;
    public readonly telecomOptions = telecomOptions;
    public readonly fixedTariffOptions = fixedTariffOptions;
    public readonly telecomProviderOptions = telecomProviderOptions;
    public readonly insurancesOptions = insurancesOptions;
    public readonly professionalOptions = professionalOptions;
    public readonly addLeaverOptions = addLeaverOptions;
    public readonly LeaverFinalInvoiceOptions = leaverFinalInvoiceOptions;
    public readonly leaverContactInvoiceOption = leaverContactInvoiceOptions;
    public readonly energyOptions = energyOptions;
    public readonly energyOption = EnergyOption;
    public readonly uiContext = UiContext;
    public readonly today = DateUtils.now();
    public readonly leadPassingFormControlsNames = LeadPassingForm;
    public readonly homeSizeStep = homeSizeStep;
    public readonly buttonAppearance = ButtonAppearance;
    public readonly buttonSize = ButtonSize;
    public readonly maskVatNumber = maskVatNumber;
    public readonly insurancesLogoUri = insurancesLogoUri;
    public readonly insurancesSupplierName = environment.insurancesPartner;
    public readonly direction = UiDirection;

    public showHomeDescriptionApartmentLevel = false;
    public showFinalInvoiceForm = true;
    public showLeaverContactAddressForm = false;
    public form: UntypedFormGroup = this.formBuilder.group({
        [LeadPassingForm.MovingDate]: [null, Validators.required],
        [LeadPassingForm.MovingAddress]: [null, Validators.required],
        [LeadPassingForm.FirstNameTransferee]: [null, Validators.required],
        [LeadPassingForm.LastNameTransferee]: [null, Validators.required],
        [LeadPassingForm.Gender]: null,
        [LeadPassingForm.DateOfBirth]: null,
        [LeadPassingForm.FirstNameLeaver]: null,
        [LeadPassingForm.LastNameLeaver]: null,
        [LeadPassingForm.EmailTransferee]: [
            null,
            {
                validators: [Validators.required, Validators.email],
                updateOn: 'blur', // validation on db for active move
            },
        ],
        [LeadPassingForm.PhoneNumberTransferee]: [null, [Validators.required, PhoneNumberValidators.isValidPhoneNumber()]],
        [LeadPassingForm.LanguageTransferee]: [null, [Validators.required]],
        [LeadPassingForm.Professional]: [null, [Validators.required]],
        [LeadPassingForm.CompanyName]: null,
        [LeadPassingForm.VatNumber]: null,
        [LeadPassingForm.EmailLeaver]: [
            null,
            {
                validators: [Validators.email],
                updateOn: 'blur', // validation on db for active move
            },
        ],
        [LeadPassingForm.PhoneNumberLeaver]: [null],
        [LeadPassingForm.LanguageLeaver]: [this.translateService.currentLang],
        [LeadPassingForm.TransfereeType]: [null, Validators.required],
        [LeadPassingForm.EnergyOption]: EnergyOption.Best,
        [LeadPassingForm.EnergySuggestion]: null,
        [LeadPassingForm.FixedTariff]: false,
        [LeadPassingForm.TransfereeRentalType]: null,
        [LeadPassingForm.AddLeaver]: true,
        [LeadPassingForm.LeaverType]: null,
        [LeadPassingForm.SmoovedTermsAndConditions]: [false, checkboxRequired],
        [LeadPassingForm.RealEstateAgentConsent]: [false, checkboxRequired],
        [LeadPassingForm.AdditionalNotes]: null,
        [LeadPassingForm.HomeDescription]: [null, Validators.required],
        [LeadPassingForm.HomeDescriptionSizeInput]: [null, Validators.required],
        [LeadPassingForm.HomeDescriptionApartmentLevel]: [null, Validators.min(0)],
        [LeadPassingForm.EnergyType]: [null, Validators.required],
        [LeadPassingForm.Vacancy]: false,
        [LeadPassingForm.HasSolarPanels]: null,
        [LeadPassingForm.ServiceInsurances]: false,
        [LeadPassingForm.ServiceTelecom]: false,
        [LeadPassingForm.TelecomBundle]: null,
        [LeadPassingForm.TelecomProvider]: null,
        [LeadPassingForm.LeaverSuppliers]: {
            energyStop: {
                hasSameSupplier: true,
            },
        },
        [LeadPassingForm.LeaverFinalInvoice]: true,
        [LeadPassingForm.LeaverContact]: LeaverContactInvoice.Digital,
        [LeadPassingForm.LeaverContactAddress]: null,
    });

    public readonly showEnergySuggestions$ = this.fieldChangeHandler<EnergyOption>(LeadPassingForm.EnergyOption).pipe(
        map((value) => [EnergyOption.ByPreference, EnergyOption.LandAgent].includes(value))
    );

    public showInsurances$: Observable<boolean>;
    public telecomSelected$: Observable<boolean>;
    public leaverInputChanged$: Observable<any>;
    public realEstateAgentConsentOption$: Observable<CheckInput<boolean>>;
    public movingDate: Date;
    public energyGroupedSuggestions$ = this.energySandbox.groupedEnergySuggestions$;
    public energyGroupedSuggestionsLoading$ = this.energySandbox.energySuggestionsLoading$;
    public allRequiredFieldsFilledInForEnergySuggestions = false;

    public readonly showFixedTariffNoOtherSuppliers$ = combineLatest([
        this.fieldChangeHandler<boolean>(LeadPassingForm.FixedTariff),
        this.energyGroupedSuggestions$,
    ]).pipe(map(([fixedTariff, suggestions]) => fixedTariff && ArrayUtils.isLength(suggestions, 1)));

    public readonly mockDataWithoutLeaver = environment.mockFormData?.leadPassingWithoutLeaver;
    public readonly mockDataWithLeaver = environment.mockFormData?.leadPassingWithLeaver;

    constructor(
        private readonly realEstateGroupSandbox: RealEstateGroupSandbox,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly moveSandbox: MoveSandbox,
        private readonly moveService: MoveService,
        private readonly energySandbox: EnergySandbox,
        private readonly translateService: TranslateService,
        private readonly moveConversionSandbox: MoveConversionSandbox,
        public readonly uiSandbox: AppUiSandbox,
        private readonly modalSandbox: ModalSandbox
    ) {
        super();
        this.form
            .get(LeadPassingForm.EmailTransferee)
            .setValidators([Validators.required, Validators.email, LeadPassingValidators.differentEmail(this.form)]);
        this.form.get(LeadPassingForm.EmailLeaver).setValidators([Validators.email, LeadPassingValidators.differentEmail(this.form)]);
        this.form.get(LeadPassingForm.TransfereeRentalType).setValidators(LeadPassingValidators.tentantType(this.form));
        this.form.get(LeadPassingForm.FirstNameLeaver).setValidators(SiblingValidators.requiredIfValidSibling(LeadPassingForm.EmailLeaver));
        this.form.get(LeadPassingForm.LastNameLeaver).setValidators(SiblingValidators.requiredIfValidSibling(LeadPassingForm.EmailLeaver));
    }

    public prefillMockDataWithoutLeaver(): void {
        this.form.get(LeadPassingForm.AddLeaver).setValue(false);
        this.form.get(LeadPassingForm.PhoneNumberLeaver).clearValidators();
        this.form.get(LeadPassingForm.PhoneNumberLeaver).updateValueAndValidity();
        this.handlePatchMove(this.mockDataWithoutLeaver);
    }

    public prefillMockDataWithLeaver(): void {
        this.form.get(LeadPassingForm.AddLeaver).setValue(true);
        this.form.get(LeadPassingForm.PhoneNumberLeaver).setValidators([Validators.required, PhoneNumberValidators.isValidPhoneNumber()]);
        this.form.get(LeadPassingForm.PhoneNumberLeaver).updateValueAndValidity();
        this.handlePatchMove(this.mockDataWithLeaver);
    }

    public ngOnInit(): void {
        // start with a clean move state
        this.initialData ? this.moveSandbox.moveOnce$.subscribe(this.handlePatchMove) : this.moveSandbox.clearMoveState();

        this.fieldChangeHandler<string>(LeadPassingForm.EmailTransferee)
            .pipe(filter((x) => !!x))
            .subscribe((value) => this.checkActiveMove(value, MoverRole.Transferee));

        this.fieldChangeHandler<string>(LeadPassingForm.EmailLeaver).subscribe(this.handleEmailLeaverChange);
        this.fieldChangeHandler<boolean>(LeadPassingForm.LeaverFinalInvoice).subscribe(this.handleLeaverFinalInvoiceChange);
        this.fieldChangeHandler<boolean>(LeadPassingForm.AddLeaver).subscribe(this.handleAddLeaverChange);
        this.fieldChangeHandler<LeaverContactInvoice>(LeadPassingForm.LeaverContact).subscribe(this.handleLeaverContactInvoiceChange);
        this.fieldChangeHandler<HomeDescription>(LeadPassingForm.HomeDescription).subscribe(this.handleHomeDescriptionChange);
        this.fieldChangeHandler<Transferee>(LeadPassingForm.TransfereeType).subscribe(() => {
            this.form.get(LeadPassingForm.TransfereeRentalType).updateValueAndValidity();
            this.handleTransfereeType();
        });
        this.fieldChangeHandler<Date>(LeadPassingForm.MovingDate).subscribe(this.handleMovingDateChanges);
        this.fieldChangeHandler(LeadPassingForm.EnergyOption).subscribe(this.handleEnergyOptionChanges);
        this.fieldChangeHandler(LeadPassingForm.EnergySuggestion).subscribe(this.handleEnergySuggestionChanges);

        this.showInsurances$ = this.showInsuranceFactory();
        this.telecomSelected$ = this.fieldChangeHandler<boolean>(LeadPassingForm.ServiceTelecom);
        this.uiSandbox.moveLoading$.pipe(takeUntil(this.destroy$)).subscribe(this.handleMoveLoading);

        this.listenForEnergySuggestions();
    }

    private listenForEnergySuggestions(): void {
        combineLatest([
            this.showEnergySuggestions$,
            this.form
                .get(LeadPassingForm.MovingAddress)
                .valueChanges.pipe(
                    startWith(this.form.get(LeadPassingForm.MovingAddress).value),
                    debounceTime(2000),
                    takeUntil(this.destroy$)
                ),
            this.fieldChangeHandler<HomeDescription>(LeadPassingForm.HomeDescription),
            this.fieldChangeHandler<boolean>(LeadPassingForm.Professional),
            this.fieldChangeHandler<EnergyType>(LeadPassingForm.EnergyType),
            this.fieldChangeHandler<boolean>(LeadPassingForm.FixedTariff),
            this.fieldChangeHandler<boolean>(LeadPassingForm.Vacancy),
        ])
            .pipe(
                tap((values) => {
                    this.allRequiredFieldsFilledInForEnergySuggestions = values.every((value) => ObjectUtils.isDefined(value));
                    if (values[0] && this.allRequiredFieldsFilledInForEnergySuggestions) {
                        this.getEnergySuggestions();
                    }
                }),
                takeUntil(this.destroy$)
            )
            .subscribe();

        this.energyGroupedSuggestions$.pipe(takeUntil(this.destroy$)).subscribe((suggestions) => {
            const firstSuggestion = ArrayUtils.first(suggestions);
            if (!firstSuggestion) return;
            this.form.get(LeadPassingForm.EnergySuggestion).patchValue(firstSuggestion);
        });

        this.leaverInputChanged$ = combineLatest([
            this.fieldChangeHandler(LeadPassingForm.FirstNameLeaver),
            this.fieldChangeHandler(LeadPassingForm.LastNameLeaver),
            this.fieldChangeHandler(LeadPassingForm.EmailLeaver),
            this.fieldChangeHandler(LeadPassingForm.PhoneNumberLeaver),
            this.fieldChangeHandler(LeadPassingForm.LeaverType),
        ]);

        this.realEstateAgentConsentOption$ = this.leaverInputChanged$.pipe(
            map((values) => {
                return values.some((value) => !!value) ? realEstateAgentConsentOptionWithLeaver : realEstateAgentConsentOptionWithoutLeaver;
            })
        );
    }

    private getEnergySuggestions(): void {
        const query: EnergySuggestionsQuery = {
            zipCode: this.form.get(LeadPassingForm.MovingAddress).value?.zipCode,
            homeDescription: this.form.get(LeadPassingForm.HomeDescription).value,
            energyType: this.form.get(LeadPassingForm.EnergyType).value,
            professional: !!this.form.get(LeadPassingForm.Professional).value,
            vacancy: !!this.form.get(LeadPassingForm.Vacancy).value,
        };
        if (!query.vacancy) {
            query.fixedTariff = this.form.get(LeadPassingForm.FixedTariff).value;
        }
        this.energySandbox.getGroupedEnergySuggestionsByConsumptionProfile(query);
    }

    public getEnergySuggestionLabelResource(groupedSuggestion: EnergySuggestion[], groupedSuggestions: EnergySuggestion[][]): string {
        if (!groupedSuggestion || !groupedSuggestions) return null;
        if (ArrayUtils.getLength(groupedSuggestions) === 1) return 'MOST_POPULAR';
        const prices = groupedSuggestions.map((groupedSuggestion) => {
            const first = ArrayUtils.first(groupedSuggestion);
            return first?.totalPriceInclusivePromotions ?? first?.totalPrice;
        });
        if (ArrayUtils.allTheSame(prices)) return null;
        const suggestion = ArrayUtils.first(groupedSuggestion);
        const price = suggestion?.totalPriceInclusivePromotions ?? suggestion?.totalPrice;
        return price === ArrayUtils.getLowest(prices) ? 'CHEAPEST' : null;
    }

    public validateForm(): void {
        this.form.markAsTouched();
        this.form.markAllAsTouched();
        if (this.form.invalid) return;

        if (this.isEnergyOptionLandAgent()) {
            const data = { data: this.translateService.instant('MOVE.USER.LANDAGENT_CONFIRM') };
            const handleConfirmation = (result: boolean) => {
                if (result) {
                    void this.submit();
                }
            };

            this.modalSandbox.openConfirmModal(data, handleConfirmation, data, handleConfirmation);
        } else {
            void this.submit();
        }
    }

    public async submit(): Promise<void> {
        const move = await firstValueFrom(this.moveSandbox.moveOnce$);
        const {
            movingDate,
            firstNameTransferee,
            lastNameTransferee,
            emailTransferee,
            movingAddress,
            phoneNumberTransferee,
            languageTransferee,
            professional,
            companyName,
            vatNumber,
            firstNameLeaver,
            lastNameLeaver,
            emailLeaver,
            phoneNumberLeaver,
            languageLeaver,
            leaverType,
            additionalNotes,
            homeDescription,
            homeDescriptionSizeInput,
            homeDescriptionApartmentLevel,
            energyType,
            vacancy,
            hasSolarPanels,
            serviceInsurances,
            serviceTelecom,
            telecomBundle,
            telecomProvider,
            smoovedTermsAndConditions,
            leaverFinalInvoice,
        } = <ILeadPassingForm>this.form.getRawValue();
        const payload: Move = ObjectUtils.buildPayload(
            merge(cloneDeep(move), {
                createdByFlow: {
                    lp: true,
                    tots: !!serviceTelecom,
                },
                movingDate: DateUtils.toDate(movingDate),
                user: {
                    firstName: firstNameTransferee,
                    lastName: lastNameTransferee,
                    email: emailTransferee,
                    movingAddress,
                    phoneNumber: phoneNumberTransferee,
                    language: languageTransferee,
                    transfereeType: this.getTransfereeTypeValue(),
                    // MoverRole is set on back-end
                },
                ...(this.leaverAdded()
                    ? {
                          firstNameLeaver,
                          lastNameLeaver,
                          emailLeaver,
                          phoneNumberLeaver,
                          languageLeaver,
                          leaverType,
                      }
                    : {}),
                professional: !!professional,
                companyName: professional ? companyName : undefined,
                vatNumber: professional ? vatNumber : undefined,
                legal: {
                    realEstateAgent: true,
                    insurancesOptInPartner: serviceInsurances ?? undefined,
                    smoovedTermsAndConditions: smoovedTermsAndConditions,
                    suppliersTermsAndConditions: this.isEnergyOptionLandAgent() && smoovedTermsAndConditions,
                },
                additionalNotes,
                energyOffer: {
                    energyType,
                    hasSolarPanels,
                    vacancy,
                },
                telecomOffer: {
                    ...telecomBundle,
                    providerChoice: telecomProvider,
                },
                meta: {
                    homeDescription,
                    homeDescriptionSizeInput,
                    homeDescriptionSize: HomeDescriptionUtils.calculateHomeDescriptionSizeBucket(homeDescriptionSizeInput, homeDescription),
                    homeDescriptionApartmentLevel,
                },
                interested: {
                    energy: true,
                    telecom: serviceTelecom,
                    insurances: serviceInsurances,
                },
            } as Move)
        ) as Move;

        const energySuggestion = ArrayUtils.first<EnergySuggestion>(this.form.get(LeadPassingForm.EnergySuggestion).value);
        if (this.showEnergySuggestion() && energySuggestion) {
            payload.energyOffer.energyOfferSelection = energySuggestion;
        }

        // When energy option is land agent, we ask for the date of birth and the gender
        if (this.isEnergyOptionLandAgent()) {
            payload.user.gender = this.form.get(LeadPassingForm.Gender).value as Gender;
            payload.user.dateOfBirth = this.form.get(LeadPassingForm.DateOfBirth).value as Date;
            payload.user.landAgent = true;
        }

        this.moveSandbox.createMove(payload, {
            clear: true,
            callback: (_id) => {
                this.moveService.get(_id).subscribe(async (move: Move) => {
                    const { transferee, leaver } = MoveUtils.getMovers(move);
                    if (this.leaverAdded() && leaverFinalInvoice) {
                        await this.leaverFinalInvoices(transferee, leaver);
                    } else {
                        this.submitSuccess.emit(transferee);
                    }
                });
            },
        });
    }

    public showEnergySuggestion(): boolean {
        const value = this.form.get(LeadPassingForm.EnergyOption)?.value as EnergyOption;
        if (!value) return false;
        return [EnergyOption.ByPreference, EnergyOption.LandAgent].includes(value);
    }

    private async leaverFinalInvoices(createdTransferee: Move, leaver: Move): Promise<void> {
        const { energyStop } = this.form.get(this.leadPassingFormControlsNames.LeaverSuppliers).value as EnergyStopForm;
        energyStop.invoiceCommunication = this.form.get(this.leadPassingFormControlsNames.LeaverContact).value;
        const contactAddress = this.form.get(this.leadPassingFormControlsNames.LeaverContactAddress).value as Address;

        await this.updateEnergyStop(DbUtils.getStringId(createdTransferee), leaver, energyStop, contactAddress);

        this.submitSuccess.emit(createdTransferee);
    }

    private async updateEnergyStop(
        transactionId: string,
        leaver: Partial<Move>,
        energyStop: Partial<EnergyStop>,
        contactAddress: Address
    ): Promise<void> {
        const energyStopActiveServices: EnergyStopActiveServices =
            energyStop?.energyStopActiveServices as unknown as EnergyStopActiveServices;
        const payload: EnergyStopRequest = {
            energyStopActiveServices,
            supplierElectricity: [EnergyStopActiveServices.Electricity, EnergyStopActiveServices.Both].includes(energyStopActiveServices)
                ? energyStop?.currentElectricitySupplier
                : null,
            supplierGas: [EnergyStopActiveServices.Gas, EnergyStopActiveServices.Both].includes(energyStopActiveServices)
                ? energyStop?.currentGasSupplier
                : null,
        };
        await this.energyStopStore.patch(transactionId, payload);

        this.moveSandbox.patchProperty(
            '',
            {
                user: { contactAddress },
            },
            true,
            null,
            false,
            leaver,
            true,
            false,
            true
        );
    }

    public isRental(): boolean {
        return this.form.get(LeadPassingForm.TransfereeType).value === Transferee.TenantTransferee;
    }

    private fieldChangeHandler<T>(field: LeadPassingForm): Observable<T> {
        return this.form.get(field).valueChanges.pipe(startWith(this.form.get(field).value), takeUntil(this.destroy$));
    }

    private getTransfereeTypeValue(): Transferee {
        return this.form.getRawValue()[this.isRental() ? LeadPassingForm.TransfereeRentalType : LeadPassingForm.TransfereeType];
    }

    private populateUser(user: User, role: MoverRole, move: Partial<Move>): void {
        this.form.patchValue(
            {
                ...this.form.getRawValue(),
                ...(move.professional
                    ? {
                          [LeadPassingForm.Professional]: true,
                          [LeadPassingForm.CompanyName]: move.companyName,
                          [LeadPassingForm.VatNumber]: move.vatNumber,
                      }
                    : {}),
                ...(role === MoverRole.Transferee
                    ? {
                          [LeadPassingForm.FirstNameTransferee]: user.firstName,
                          [LeadPassingForm.LastNameTransferee]: user.lastName,
                          [LeadPassingForm.PhoneNumberTransferee]: user.phoneNumber,
                          [LeadPassingForm.Gender]: user.gender,
                      }
                    : {
                          [LeadPassingForm.FirstNameLeaver]: user.firstName,
                          [LeadPassingForm.LastNameLeaver]: user.lastName,
                          [LeadPassingForm.PhoneNumberLeaver]: user.phoneNumber,
                      }),
            },
            { emitEvent: false }
        );

        this.form
            .get(role === MoverRole.Transferee ? LeadPassingForm.EmailTransferee : LeadPassingForm.EmailLeaver)
            .updateValueAndValidity({ emitEvent: false });
    }

    private leaverTypeValidation(value: any): void {
        if (!value || value === '') {
            this.form.get(LeadPassingForm.LeaverType).setValidators(null);
            this.form.get(LeadPassingForm.LeaverType).updateValueAndValidity();
            return;
        }
        this.form.get(LeadPassingForm.LeaverType).setValidators(Validators.required);
        this.form.get(LeadPassingForm.LeaverType).updateValueAndValidity();
    }

    private showInsuranceFactory(): Observable<boolean> {
        return combineLatest([
            this.realEstateGroupSandbox.serviceInsurances$,
            this.fieldChangeHandler<Transferee>(LeadPassingForm.TransfereeRentalType),
        ]).pipe(map(([serviceInsurances, type]) => serviceInsurances && type === Transferee.TenantTransferee));
    }

    private leaverAdded(): boolean {
        return this.form.get(LeadPassingForm.AddLeaver).value;
    }

    private handlePatchMove = (move: Partial<Move>): void => {
        const {
            movingDate,
            leaverType,
            emailLeaver,
            phoneNumberLeaver,
            languageLeaver,
            firstNameLeaver,
            lastNameLeaver,
            user,
            meta,
            energyOffer,
            professional,
            companyName,
            vatNumber,
        } = move || {};
        const { homeDescription, homeDescriptionSizeInput, homeDescriptionApartmentLevel } = meta || {};
        const {
            movingAddress,
            email: emailTransferee,
            firstName: firstNameTransferee,
            lastName: lastNameTransferee,
            dateOfBirth,
            gender,
            phoneNumber: phoneNumberTransferee,
            language: languageTransferee,
            transfereeType,
        } = user || {};
        const patch = {
            transfereeType,
            movingDate,
            movingAddress,
            emailTransferee,
            firstNameTransferee,
            lastNameTransferee,
            dateOfBirth,
            gender,
            phoneNumberTransferee,
            languageTransferee,
            transfereeRentalType: [Transferee.TenantTransferee, Transferee.OwnerLandlord].includes(transfereeType) ? transfereeType : null,
            leaverType,
            emailLeaver,
            firstNameLeaver,
            lastNameLeaver,
            phoneNumberLeaver,
            languageLeaver,
            homeDescription,
            homeDescriptionSizeInput,
            homeDescriptionApartmentLevel,
            professional,
            ...(professional ? { vatNumber, companyName } : {}),
            energyType: energyOffer?.energyType,
        };
        this.form.patchValue(patch);
        this.handleHomeDescriptionChange(homeDescription);
    };

    private checkActiveMove = (value: string, role: MoverRole): void => {
        this.moveSandbox.getActiveMoveByEmail(value).subscribe((activeMove: Move) => this.handleActiveMove(activeMove, role));
    };

    private handleActiveMove = (activeMove: Move, role: MoverRole): void =>
        this.moveConversionSandbox.showPrefillMove(activeMove, () => this.populateUser(activeMove?.user, role, activeMove), role);

    private handleEmailLeaverChange = (value: string): void => {
        this.leaverTypeValidation(value);
        if (value) {
            this.checkActiveMove(value, MoverRole.Leaver);
        }
    };

    private handleLeaverFinalInvoiceChange = (value: boolean): void => {
        this.showFinalInvoiceForm = value;
        this.handleAddLeaverChange(value);
    };

    private handleAddLeaverChange = (value: boolean): void => {
        this.form.get(LeadPassingForm.LeaverFinalInvoice).setValue(value, { emitEvent: false });
        if (value) {
            this.form.addControl(
                LeadPassingForm.LeaverSuppliers,
                this.formBuilder.control(
                    {
                        energyStop: {
                            hasSameSupplier: true,
                        },
                    },
                    Validators.required
                )
            );
        } else {
            this.form.removeControl(LeadPassingForm.LeaverSuppliers);
        }

        if (this.leaverAdded()) {
            this.form
                .get(LeadPassingForm.PhoneNumberLeaver)
                .setValidators([Validators.required, PhoneNumberValidators.isValidPhoneNumber()]);
        } else {
            this.form.get(LeadPassingForm.PhoneNumberLeaver).clearValidators();
        }
        this.form.get(LeadPassingForm.PhoneNumberLeaver).updateValueAndValidity();
    };

    private handleLeaverContactInvoiceChange = (value: LeaverContactInvoice): void => {
        this.showLeaverContactAddressForm = value === LeaverContactInvoice.ByPost;
    };

    private handleHomeDescriptionChange = (value: HomeDescription): void => {
        this.showHomeDescriptionApartmentLevel = value === HomeDescription.Apartment;
    };

    private handleMoveLoading = (loading: boolean): void => {
        loading ? this.uiSandbox.showLoadingOverlay() : this.uiSandbox.hideLoadingOverlay();
    };

    private handleMovingDateChanges = (): void => {
        this.movingDate = this.form.get(LeadPassingForm.MovingDate).value;
    };

    private handleEnergyOptionChanges = (energyOption: EnergyOption): void => {
        if (energyOption === EnergyOption.LandAgent) {
            this.form.get(LeadPassingForm.Gender).setValidators(Validators.required);
            this.form.get(LeadPassingForm.DateOfBirth).setValidators(Validators.required);
        } else {
            this.form.get(LeadPassingForm.Gender).clearValidators();
            this.form.get(LeadPassingForm.DateOfBirth).clearValidators();
        }
        this.form.get(LeadPassingForm.Gender).updateValueAndValidity();
        this.form.get(LeadPassingForm.DateOfBirth).updateValueAndValidity();

        const payload: Partial<Move> = {
            energySelected: this.isEnergyOptionLandAgent(),
        };

        if (energyOption === EnergyOption.Best) {
            payload.energyOffer = {
                energyOfferSelection: null,
            };
        }
        this.moveSandbox.patchProperty('', payload);
    };

    public isEnergyOptionLandAgent(): boolean {
        return this.form.get(LeadPassingForm.EnergyOption).value === EnergyOption.LandAgent;
    }

    private handleEnergySuggestionChanges = (groupedEnergySuggestion: EnergySuggestion[]): void => {
        const energyOfferSelection = ArrayUtils.first(groupedEnergySuggestion);

        this.moveSandbox.patchProperty('', {
            energySelected: this.isEnergyOptionLandAgent(),
            energyOffer: {
                energyOfferSelection,
            },
        });
    };

    private handleTransfereeType = (): void => {
        this.form.get(LeadPassingForm.TransfereeType).value === Transferee.OwnerTransferee
            ? this.form.get(LeadPassingForm.TransfereeRentalType).patchValue(Transferee.OwnerLandlord)
            : this.form.get(LeadPassingForm.TransfereeRentalType).patchValue(null);
    };
}
