import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-offer-selected',
    template: `
        <app-card-container>
            <div class="u-container u-margin-0-auto">
                <app-card class="u-flex-column u-flex-align-items-center">
                    <img [src]="imageSrc" class="u-margin-bottom-double h-[80px]" />

                    <div class="u-container-small u-flex-column u-flex-align-items-center">
                        <div
                            class="u-flex-row u-flex-align-items-center u-margin-bottom-double u-padding-bottom-double u-bordered-bottom u-w100p u-flex-justify-content-center"
                        >
                            <app-check-with-background [width]="22" [height]="22" class="u-margin-right-half"></app-check-with-background>
                            <h5>{{ name }}</h5>
                        </div>

                        <h3 class="u-margin-bottom">{{ title }}</h3>

                        <p class="u-font-size-medium u-margin-bottom-double u-text-align-center">{{ text }}</p>

                        <app-button theme="secondary" [routerLink]="primaryRoute" class="u-margin-bottom">{{ primaryCtaLabel }}</app-button>

                        <app-button theme="secondary-outline" [routerLink]="secondaryRoute">{{ secondaryCtaLabel }}</app-button>
                    </div>
                </app-card>
            </div>
        </app-card-container>
    `,
    styles: [],
})
export class OfferSelectedComponent {
    @Input() public primaryRoute: string;
    @Input() public primaryCtaLabel: string;
    @Input() public secondaryRoute: string;
    @Input() public secondaryCtaLabel: string;
    @Input() public imageSrc: string;
    @Input() public title: string;
    @Input() public name: string;
    @Input() public text: string;
}
